<template>
	<li @click="clickEvent()"
		:key="event.uid" class="list-group-item ribbon ribbon-bottom ribbon-right ribbon-modern" 
		:class="{'ribbon-success':  finished, 'ribbon-warning': waiting}"
		:style="{'border-left-color': event.color, 'opacity': finished && getSyncStatus===1 ? 0.7:1}">
		<div class="d-flex justify-content-between">
			<div>
				<div v-if="event.client.name" class="text-info font-weight-bolder">
					{{event.client.name}}<template v-if="event.client.addressname"> - {{event.client.addressname}}</template>
				</div>
				<div> {{event.typelibelle}}</div>

				<div class="font-size-sm font-weight-bold text-muted">
					{{event.title}} <span v-if="event.comment"><i class="far fa-comment-alt-dots ml-2"></i></span>
				</div>
			</div>
			<div class="font-size-xs">
				<div class="font-weight-bolder">{{event.start | formatTime}}&nbsp;&rarr;&nbsp;{{getEnd(event) | formatTime}}</div>
			</div>
		</div>
		<div v-if="event.client.address" class="font-size-sm">
			{{event.client.address}} {{event.client.zipcode}} {{event.client.city}}
		</div>
		<div class="font-size-sm d-flex justify-content-between mt-2">
			<div>
				<div v-if="getSyncStatus===0" class="font-weight-bolder rounded-pill bg-warning-light text-warning py-1 px-4">
					<i class="fas fa-cloud mr-2"></i> {{ $t('non synchronisé') }}
				</div>
				<div v-if="getSyncStatus===2" class="font-weight-bolder rounded-pill bg-info-light text-info py-1 px-4">
					<i class="fas fa-spin fa-spinner mr-2"></i> {{ $t('synchronisation en cours') }}
				</div>
			</div>
			<div></div>
		</div>
		<div v-if="finished" class="ribbon-box">
			<i class="fas fa-check-circle mr-2"></i> 
			<template v-if="getCountResponses">
				{{ getCountResponses }} {{ $tc('rapport',getCountResponses) }}
			</template>
		</div>
		<div v-if="waiting" class="ribbon-box">
			<i class="far fa-clock"></i> 
			<template v-if="hasRapportEnCours">
				{{ $t('rapport en cours') }}
			</template>
			<template v-else-if="intervention.started_at&&!intervention.ended_at">
				{{ $t('intervention démarrée') }}
			</template>
			<template v-else-if="getCountResponses">
				{{ getCountResponses }} {{ $tc('rapport',getCountResponses) }}
			</template>
		</div>
	</li>
</template>

<script>
	import moment from 'moment';
	moment.locale('fr');
	import {mapGetters, mapActions} from 'vuex';
	
	export default {
		name: 'ListOneEvent',
		props: ['event'],
		beforeMount: function () {
			this.intervention = this.getIntervention(this.event.uid);
		},
		data: function () {
			return {
				intervention: {},
			}
		},
		computed: {
			...mapGetters('interventions', [
				'getIntervention'
			]),
			finished(){
				// intervention finie et rapport complété (ou pas de rapport)
				return this.intervention.syncfinished && !this.waiting;
			},
			waiting(){
				return this.hasRapportEnCours || (this.intervention.started_at && ! this.intervention.ended_at);
			},
			hasRapportEnCours(){
				if (Object.keys(this.intervention.responses).length == 0) {
					return false;
				}
				
				return !_.reduce(this.intervention.responses, (res, obj) => res&&obj.finished, true)
			},
			getCountResponses() {
				return Object.keys(this.intervention.responses).length;
			},
			getSyncStatus() {
				return this.intervention.sync
			},
		},
		methods: {
			clickEvent: function () {
                this.$router.push({name: 'event', params: {eventid: this.event.uid}});
			},
			getEnd() {
				return moment(this.event.start).add(this.event.duration, 'minutes');
			}
		}
	};
</script>
